import { ProgramRegistrationQuestion } from '@guider-global/shared-types';
import { Box, Typography } from '@mui/material';
import { Asterisk, FormTextFieldTyped } from 'components';
import { FormAutocomplete } from 'components/FormAutocomplete';
import { Control, FieldErrors } from 'react-hook-form';

export const renderFields = ({
  registrationQuestions,
  isSkillsEnabled,
  typedControl,
  errors,
  pageName,
  role,
  isGroupProgram,
}: {
  registrationQuestions: ProgramRegistrationQuestion[] | undefined;
  isSkillsEnabled: boolean | undefined;
  typedControl: Control;
  errors: FieldErrors;
  pageName: string;
  role: 'guide' | 'trainee';
  isGroupProgram: boolean;
}) => {
  const filteredRegistrationQuestions =
    registrationQuestions?.filter((registrationQuestion) =>
      [role, 'both'].includes(registrationQuestion.audience),
    ) || [];

  return filteredRegistrationQuestions.map((question, index) =>
    renderFieldWrapper({
      question,
      typedControl,
      errors,
      pageName,
      index,
      isSkillsEnabled,
      role,
      isGroupProgram,
    }),
  );
};

export const renderFieldWrapper = ({
  question,
  typedControl,
  errors,
  pageName,
  index,
  isSkillsEnabled,
  role,
  isGroupProgram,
}: {
  question: ProgramRegistrationQuestion;
  typedControl: Control;
  errors: FieldErrors;
  pageName: string;
  index: number;
  isSkillsEnabled: boolean | undefined;
  role: 'guide' | 'trainee';
  isGroupProgram: boolean;
}) => {
  const isGuide = role === 'guide';
  const questionId = question.id.current;
  const goalCategoriesOffset = isGuide && isSkillsEnabled ? 1 : 0;
  const questionIndexOffset = isGroupProgram && isGuide ? 2 : 0; // guide has 2 extra required questions
  const questionIndex =
    index +
    (isSkillsEnabled ? 2 : 1) +
    questionIndexOffset +
    goalCategoriesOffset;
  const isRequired = question.required ?? false;

  return (
    <Box
      sx={{ ':not(:first-of-type)': { mt: 6 } }}
      key={`program-registration-question-${questionId}`}
    >
      <Typography variant="h6">
        {`${questionIndex}. `}
        {question?.[role]?.question_title}
        {isRequired && <Asterisk />}
      </Typography>
      <Typography
        variant="subtitle2"
        color="text.secondary"
        sx={{ whiteSpace: 'pre-line' }}
      >
        {question?.[role]?.question_description}
      </Typography>
      {renderField({ question, typedControl, errors, pageName })}
    </Box>
  );
};

export const renderField = ({
  question,
  typedControl,
  errors,
  pageName,
}: {
  question: ProgramRegistrationQuestion;
  typedControl: Control;
  errors: FieldErrors;
  pageName: string;
}) => {
  const isRequired = question.required ?? false;

  const questionId = question.id.current;
  const questionType = question.type;
  const questionLabel = question?.text?.input_label ?? '';

  const requiredErrorText =
    (isRequired ? question.required_validation_error_text : '') ?? '';

  if (questionType === 'free-text') {
    const freeTextConfig = question.free_text_config;
    const rows = freeTextConfig?.text_area_size === 'multiline' ? 4 : 1;
    const minLengthErrorMessage =
      freeTextConfig?.text?.minimum_length_not_met_validation_error;
    const maxLengthErrorMessage =
      freeTextConfig?.text?.maximum_length_exceeded_validation_error;
    const minLength = freeTextConfig?.minimum_length;
    const maxLength = freeTextConfig?.maximum_length;

    return (
      <FormTextFieldTyped
        name={questionId}
        control={typedControl}
        label={questionLabel}
        errors={errors}
        rows={rows}
        rules={{
          required: isRequired,
          minLength: minLength,
          maxLength: maxLength,
        }}
        requiredErrorMessage={requiredErrorText}
        minLengthErrorMessage={minLengthErrorMessage}
        maxLengthErrorMessage={maxLengthErrorMessage}
        data-cy={`pages_${pageName}_${questionId}-free-text-field`}
      />
    );
  }

  if (questionType === 'select') {
    const questionOptions =
      question?.options?.map((option) => ({
        label: option.label ?? '',
        value: option.id.current,
      })) ?? [];

    return (
      <FormAutocomplete
        defaultValue={''}
        name={questionId}
        control={typedControl}
        label={questionLabel ?? ''}
        errors={errors}
        options={questionOptions ?? []}
        rules={{ required: isRequired }}
        data-cy={`pages_${pageName}_${questionId}-select-field`}
      />
    );
  }

  if (questionType === 'multi-select') {
    const multiselectConfig = question.multi_select_config;
    const questionOptions =
      question?.options?.map((option) => ({
        label: option.label ?? '',
        value: option.id.current,
      })) ?? [];

    const maximumSelections = multiselectConfig?.maximum_selections ?? 5;
    const minimumSelections = multiselectConfig?.minimum_selections ?? 0;
    const errorText = multiselectConfig?.text;

    const minimumSelectionsNotMetValidationError =
      errorText?.minimum_selections_not_met_validation_error;
    const maximumSelectionsExceededValidationError =
      errorText?.maximum_selections_exceeded_validation_error;

    // Events
    const multiSelectValidate = (value: unknown) => {
      if (question.type === 'multi-select') {
        const valueLength = (value as string[]).length;
        if (valueLength > maximumSelections)
          return maximumSelectionsExceededValidationError;
        if (valueLength < minimumSelections)
          return minimumSelectionsNotMetValidationError;
        return true;
      }
    };

    return (
      <FormAutocomplete
        multiple
        defaultValue={[]}
        name={questionId}
        control={typedControl}
        label={questionLabel}
        errors={errors}
        options={questionOptions}
        rules={{ required: isRequired, validate: multiSelectValidate }}
        data-cy={`pages_${pageName}_${questionId}-multiselect-field`}
      />
    );
  }
};
