import { useGoals } from 'hooks';
import { LoadingOverlay } from 'components';
import { useAuth } from '@guider-global/auth-hooks';
import { Button, Stack, TextField, Text } from '@guider-global/ui';
import { useState } from 'react';

export const PaginationDemoPage: React.FC = () => {
  // Handle auth
  useAuth({});

  const {
    goals,
    isSuccessGoals,
    getMeta,
    getNextPage,
    getPreviousPage,
    getPage,
    getMore,
    hasMore,
  } = useGoals({
    getSilently: true,
    isPaginated: true,
    pageLimit: 2,
  });

  const [page, setPage] = useState<number>(1);

  const isReady = isSuccessGoals();

  if (!isReady) return <LoadingOverlay loading={!isReady} />;

  return (
    <Stack direction="column" spacing={1}>
      <Text variant="h3" text="Demo Pagination functionality" />
      <Text variant="h4" sx={{ mb: 2 }} text="Redux: data" />
      <pre>{JSON.stringify(goals(), null, 2)}</pre>
      <Text variant="h4" sx={{ mb: 2 }} text="Redux: meta" />
      <pre>{JSON.stringify(getMeta(), null, 2)}</pre>
      <Text variant="h4" sx={{ mb: 2 }} text="Redux: hasMore" />
      <pre>{hasMore() ? 'true' : 'false'}</pre>

      <Stack direction={'row'}>
        <Button label="getNextPage()" onClick={() => getNextPage({})} />
        <Button label="getPrevious()" onClick={() => getPreviousPage({})} />
        <Button label="getMore()" onClick={() => getMore({})} />
        <TextField
          label="Page number"
          type="number"
          value={page}
          onChange={(e) => setPage(Number(e.target.value))}
        />
        <Button label={`getPage(${page})`} onClick={() => getPage({ page })} />
      </Stack>
    </Stack>
  );
};

export default PaginationDemoPage;
