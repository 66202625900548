// external
import React, { useEffect } from 'react';
import { Box, Button, Paper, Typography, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
// components
import { PageCard } from 'components';

// internal
import { useAppDispatch } from 'store/hooks';
import { hideConfetti, showConfetti } from 'store/slices/appSlice';
import {
  useSanityBaseLanguage,
  useSanityOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import { useMobileMediaQuery } from 'hooks';

export const GuideRegistrationPublishedPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMobileMediaQuery();

  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  useEffect(() => {
    dispatch(showConfetti(true));
    const timer = setTimeout(() => {
      dispatch(hideConfetti(true));
    }, 5000);
    return () => clearTimeout(timer);
  }, [dispatch]);

  const { programSlug = '', role } = useParams<{
    programSlug: string;
    role: 'guide' | 'trainee';
  }>();

  const { getProgram } = useSanityOrganizationPrograms({});
  const program = getProgram(programSlug);

  if (!program || !role) {
    return <></>;
  }

  const isGuide = role === 'guide';

  if (!isGuide) {
    navigate(`/programs/${programSlug}`);
  }

  const programType = program.program_details?.program_type;
  const programVariation =
    programType?.program_type_text?.variations?.individual;

  return (
    <Paper
      sx={{
        pt: isMobile ? 0 : 4,
        pb: 4,
        borderTop: `8px solid ${theme.palette.secondary.main}`,
        ...(isMobile && { borderRadius: '0px' }),
      }}
    >
      <PageCard
        title={
          programVariation?.registration?.registration_guide
            ?.registration_guide_complete?.registration_guide_complete_title ??
          ''
        }
        subtitle={
          programVariation?.registration?.registration_guide
            ?.registration_guide_complete
            ?.registration_guide_complete_description ?? ''
        }
        color="success"
        icon={CheckCircleOutline}
        textAlign={isMobile ? 'center' : 'start'}
      />
      <Typography
        variant="body2"
        sx={{
          mt: isMobile ? 3 : 5,
          mb: isMobile ? 3 : 2,
          px: isMobile ? 2 : 3,
          textAlign: isMobile ? 'center' : 'start',
        }}
      >
        {
          programVariation?.registration?.registration_guide
            ?.registration_guide_complete
            ?.registration_guide_complete_actions_label
        }
      </Typography>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-end',
          px: isMobile ? 2 : 3,
        }}
      >
        <Button
          data-cy="pages-ProgramRegistrationPage-Guide-GuideRegistrationPublishedPage_programs-button"
          variant="contained"
          color="info"
          size="large"
          fullWidth={isMobile}
          onClick={() => navigate('/dashboard')}
          sx={{ mr: isMobile ? 0 : 3 }}
        >
          {
            programVariation?.registration?.registration_guide
              ?.registration_guide_complete
              ?.registration_guide_complete_dashboard_button_label
          }
        </Button>
        <Button
          data-cy="pages-ProgramRegistrationPage-Guide-GuideRegistrationPublishedPage_learn-button"
          variant="outlined"
          color="info"
          size="large"
          sx={{
            mt: isMobile ? 2 : 0,
          }}
          fullWidth={isMobile}
          onClick={() => navigate('/learn')}
        >
          {baseLanguage.globals.common.explore_learning_hub}
        </Button>
      </Box>
    </Paper>
  );
};
