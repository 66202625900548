// external
import { useAuth } from '@guider-global/auth-hooks';
import { Grid, Skeleton } from '@mui/material';

// components
import { PageHeader, PageWrapper } from 'components';
import ProgramListCard from 'components/Programs/ProgramListCard';
import ProgramsListEmpty from 'components/Programs/ProgramsListEmpty';

// hooks
import { getSubDomain } from '@guider-global/front-end-utils';
import {
  useSanityBaseLanguage,
  useSanityOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import {
  EProgramVariation,
  ICustomField,
  Program,
} from '@guider-global/shared-types';
import ProgramListCardGroup from 'components/Programs/ProgramListCardGroup';
import {
  useLocalization,
  useMemberships,
  useMixpanelEvents,
  useMobileMediaQuery,
  useProfiles,
  useTrackEvent,
} from 'hooks';

export const ProgramsListPage = () => {
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);

  // Auth
  const auth = useAuth({ waitForAuthentication: true });
  // Styles
  const isMobile = useMobileMediaQuery();

  // Base Language
  const { getBaseLanguage, isLoadingSanityBaseLanguage } =
    useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  // Profile
  const { getProfiles, isLoadingProfiles } = useProfiles({});
  const [profile] = getProfiles();
  const profileOrganizationFields =
    profile?.organizationFields as ICustomField[];

  // Memberships
  const { memberships: getMemberships, isLoadingMemberships } = useMemberships(
    {},
  );
  const memberships = getMemberships();

  // Programs
  const { isLoadingSanityPrograms, getAuthorizedPrograms } =
    useSanityOrganizationPrograms({
      organizationSlug,
      localeCode,
    });
  const programs = getAuthorizedPrograms(
    profileOrganizationFields,
    memberships,
  );

  // Derivations
  const isResourcesLoading =
    isLoadingMemberships() ||
    isLoadingSanityBaseLanguage() ||
    isLoadingProfiles() ||
    isLoadingSanityPrograms();

  const {
    empty_state: emptyState,
    title: programsListTitle,
    subtitle: programsListSubtitle,
  } = baseLanguage.programs.programs_list;

  const emptyTitle = emptyState.title;
  const emptyDescription = emptyState.description;

  useTrackEvent({
    track: {
      eventKey: 'program-list-visited',
      eventParams: {
        organization: { organizationSlug },
        programList: { organizationSlug },
      },
    },
    for: organizationSlug,
    if: auth.isAuthorized,
  });

  useMixpanelEvents({ navigationEventName: 'Programs' });

  return (
    <>
      <PageHeader header={programsListTitle} subheader={programsListSubtitle} />
      <PageWrapper
        sx={{
          minHeight: '500px',
        }}
      >
        <Grid
          container
          sx={{ width: isMobile ? '95%' : '70%', maxWidth: '1440px' }}
          spacing={3}
          direction="row"
          alignItems="stretch"
        >
          {programs.map((program: Program) =>
            program.program_details?.program_variation ===
            EProgramVariation.Individual ? (
              <ProgramListCard
                program={program}
                key={`programs-list-${program.metadata.id.current}`}
              />
            ) : (
              <ProgramListCardGroup
                program={program}
                key={`programs-list-${program.metadata.id.current}`}
              />
            ),
          )}

          <ProgramsListEmpty
            title={emptyTitle}
            subtitle={emptyDescription}
            show={!isResourcesLoading && programs.length === 0}
          />
          <ProgramLoadingCard loading={isResourcesLoading} />
        </Grid>
      </PageWrapper>
    </>
  );
};

function ProgramLoadingCard({ loading }: { loading: boolean }) {
  if (!loading) return null;
  return (
    <Grid key={`programs-loading`} item xs={12} md={6}>
      <Skeleton
        sx={{
          width: '100%',
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
          minHeight: '400px',
          transform: 'none',
        }}
      />
    </Grid>
  );
}
