// external
import { useEffect } from 'react';
import { Card, Fade } from '@mui/material';
import { Outlet } from 'react-router-dom';

// components
import { GenericError } from 'components';

// store
import { useAppDispatch } from 'store/hooks';
import { appSlice } from 'store/slices/appSlice';

// hooks
import { useProfileForm } from 'forms/ProfileForms/ProfileForm/hooks';

export const OnboardingPage: React.FC = () => {
  // Redux
  const dispatch = useAppDispatch();

  // TODO Refactor to UI Specific store that extends IReduxState via createReduxSlice()
  const { hideNavbar } = appSlice.actions;

  const {
    sanityOrganizationWhiteLabel,
    hasResultsOrganizations,
    isSuccessOrganizations,
    isErrorUsers,
    getErrorsUsers,
    isErrorProfiles,
    getErrorsProfiles,
    isErrorCustomFields,
    getErrorsCustomFields,
  } = useProfileForm({
    getSanityBaseLanguageSilently: true,
    getSanityOrganizationSilently: true,
    getProfilesSilently: true,
    getUsersSilently: true,
    getOrganizationsSilently: true,
    getCustomFieldsSilently: true,
  });

  // TODO Declare a global style for this or identify existing?
  const flexStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  // TODO Refactor into new UI hook
  // Branding state
  useEffect(() => {
    if (sanityOrganizationWhiteLabel) {
      dispatch(hideNavbar(true));
    }
  }, [sanityOrganizationWhiteLabel, hideNavbar, dispatch]);

  // Handle errors from the server side
  // TODO Move to centralised GenericErrorContainer
  function isError(): boolean {
    if (isSuccessOrganizations() && !hasResultsOrganizations()) {
      return true;
    }
    if (isErrorProfiles() || isErrorUsers() || isErrorCustomFields()) {
      return true;
    }
    return false;
  }
  // TODO Move to centralised GenericErrorContainer
  function errorCode(): string {
    if (!hasResultsOrganizations()) {
      return `ORGANIZATIONS_MISSING`;
    } else if (isErrorProfiles()) {
      return `PROFILES_ERRORS`;
    } else if (isErrorCustomFields()) {
      return `CUSTOMFIELDS_ERRORS`;
    } else if (isErrorUsers()) {
      return `USERS_ERROR`;
    }
    return 'UNEXPECTED-ERROR';
  }
  // TODO Move to centralised GenericErrorContainer
  function errorMessage(): string {
    switch (errorCode()) {
      case 'ORGANIZATIONS_MISSING':
        return `Could not find organizations`;
      case 'PROFILES_ERRORS':
        return getErrorsProfiles()
          .map((err) => `${err.code} ${err.field} ${err.message}`)
          .join(',');
      case 'USERS_ERROR':
        return getErrorsUsers()
          .map((err) => `${err.code} ${err.field} ${err.message}`)
          .join(',');
      case 'CUSTOMFIELDS_ERRORS':
        return getErrorsCustomFields()
          .map((err) => `${err.code} ${err.field} ${err.message}`)
          .join(',');
      case 'UNEXPECTED-ERROR':
      default:
        return 'An unexpected error occurred';
    }
  }

  return (
    <>
      {isError() ? (
        // TODO Move to top level error handler
        <>
          <GenericError
            code={errorCode()}
            message={errorMessage()}
            visible={isError()}
            overlay={false}
          />
        </>
      ) : (
        <Card
          sx={{
            ...flexStyle,
            border: '1px solid rgb(224, 224, 224, 0.6)',
            boxShadow: 'none',
            borderRadius: '4px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            mx: 2,
            width: ['100%', '450px'],
          }}
        >
          <Outlet />
        </Card>
      )}
    </>
  );
};
