// external
import React, { useEffect, useState } from 'react';
import { Box, Divider, Paper, ThemeProvider, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

// components
import { PageCard } from 'components';

// store
import { useAppDispatch } from 'store/hooks';

// hooks
import { useMemberships, useMobileMediaQuery } from 'hooks';

// types
import {
  useSanityBaseLanguage,
  useSanityOrganization,
  useSanityOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import { Checkbox, Stack, theme } from '@guider-global/ui';
import { deepMerge } from 'utils';
import { showAppAlert } from 'store/slices/appSlice';
import { IMembership } from '@guider-global/shared-types';
import { getSubDomain } from '@guider-global/front-end-utils';
import { LoadingButton } from '@mui/lab';

export const ProgramRegistrationGoalsPage: React.FC = () => {
  // Internal state
  const [selectedGoalCategories, setSelectedGoalCategories] = useState<
    string[]
  >([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Redux

  const dispatch = useAppDispatch();

  // React Router
  const navigate = useNavigate();
  const { programSlug = '', role } = useParams<{
    programSlug: string;
    role: 'guide' | 'trainee';
  }>();

  // Styling
  const organizationTheme = useTheme();
  const isMobile = useMobileMediaQuery();

  const combinedPalette = deepMerge(
    theme.appTheme.palette,
    organizationTheme.palette,
  );

  // - Base Language
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  // - Memberships

  const { reqMemberships, memberships } = useMemberships({});

  const programMemberships = memberships().filter(
    (membership) => membership.programSlug === programSlug,
  );

  const membership = programMemberships.find(
    (membership) => membership.role === role,
  );

  useEffect(() => {
    if (!membership?.goalCategories) {
      return;
    }

    const initialGoalCategories = membership.goalCategories.map(
      (goal) => goal.slug,
    );

    setSelectedGoalCategories(initialGoalCategories);
  }, [membership]);

  // Get Sanity Goal Categories

  const subdomain = getSubDomain();

  const { getOrganization } = useSanityOrganization({
    getSilently: true,
    organizationSlug: subdomain,
    localeCode: 'en_GB',
  });

  const organization = getOrganization();

  const goalCategories = organization.goal_categories.goal_categories.map(
    (goalCategory) => ({
      label: goalCategory.goal_category_name,
      slug: goalCategory.goal_category_slug.current,
    }),
  );

  // - Programs

  const { getProgram } = useSanityOrganizationPrograms({});
  const program = getProgram(programSlug);

  if (!program || !role || !goalCategories) {
    return <></>;
  }

  const programType = program?.program_details?.program_type?.program_type_text;
  const programRegistration = programType?.variations?.individual?.registration;

  function isGoalCategorySelected(goal: string) {
    return selectedGoalCategories.find((g) => g === goal) ? true : false;
  }

  const toggleGoalCategorySelect = (goal: string) => {
    isGoalCategorySelected(goal)
      ? setSelectedGoalCategories([
          ...selectedGoalCategories.filter((selected) => selected !== goal),
        ])
      : setSelectedGoalCategories([...selectedGoalCategories, goal]);
  };

  const handleGoalCategories = async () => {
    if (!selectedGoalCategories || role === 'guide') {
      return;
    }
    setIsLoading(true);

    const data: Partial<IMembership> & { goalCategorySlugs: string[] } = {
      role: 'trainee',
      isPublished: true,
      goalCategorySlugs: selectedGoalCategories,
    };

    const membershipsResult = await reqMemberships({
      method: 'PATCH',
      url: `/memberships/${membership?.id}`,
      data,
    });

    if (membershipsResult.status === 'success') {
      navigate('../choose');
    } else {
      dispatch(
        showAppAlert({
          severity: 'error',
          message: membershipsResult.errors?.at(0)?.message,
          timeout: 3000,
        }),
      );
    }
    setIsLoading(false);
  };

  return (
    <Paper
      sx={{
        pt: isMobile ? 0 : 4,
        pb: 4,
        borderTop: `8px solid ${combinedPalette.secondary.main}`,
        ...(isMobile && { borderRadius: '0px' }),
      }}
    >
      <PageCard
        title={
          programRegistration?.registration_trainee?.registration_trainee_goals
            ?.registration_trainee_goals_title ?? ''
        }
        subtitle={
          programRegistration?.registration_trainee?.registration_trainee_goals
            ?.registration_trainee_goals_description ?? ''
        }
      />
      {!isMobile && <Divider sx={{ my: 4, mx: 3 }} />}
      <ThemeProvider theme={{ ...theme.appTheme, palette: combinedPalette }}>
        <Box
          sx={{ my: 4, mx: 3 }}
          display={'flex'}
          flex={'row'}
          justifyContent={'center'}
        >
          <Stack direction={'column'} width={{ xs: '100%', md: '60%' }}>
            {goalCategories.map((goal) => (
              <Checkbox
                variant="option"
                heading={goal.label}
                isChecked={isGoalCategorySelected(goal.slug)}
                onChange={() => toggleGoalCategorySelect(goal.slug)}
              />
            ))}
          </Stack>
        </Box>
      </ThemeProvider>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: isMobile ? 'column-reverse' : 'row',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',

          mt: 3,
          px: isMobile ? 2 : 3,
        }}
      >
        <LoadingButton
          variant="contained"
          color="info"
          size="large"
          loading={isLoading}
          fullWidth={isMobile}
          onClick={handleGoalCategories}
          disabled={selectedGoalCategories.length === 0}
        >
          {baseLanguage.globals.common.continue_button_label}
        </LoadingButton>
      </Box>
    </Paper>
  );
};
