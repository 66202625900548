// external
import PersonSearchSharp from '@mui/icons-material/PersonSearchSharp';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Fade,
  Modal,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// internal
import { hexToRGB, prepareSanityString } from 'utils';

// components
import { AvatarUser, GuideAvatar, GuideCard, PageCard } from 'components';

// store
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  hideConfetti,
  showAppAlert,
  showConfetti,
} from 'store/slices/appSlice';
import {
  selectMembershipTraineeForm,
  setMembershipTraineeForm,
} from 'store/slices/formsSlice';

// hooks
import {
  useGoals,
  useMatches,
  useMemberships,
  useMixpanelEvents,
  useMobileMediaQuery,
  useProfiles,
  useRelationships,
  useTrackEvent,
} from 'hooks';

// types
import {
  EProgramVariation,
  IMatch,
  IMatchMembership,
  IMembership,
  IProfile,
  ISkill,
} from '@guider-global/shared-types';

import { getSubDomain } from '@guider-global/front-end-utils';
import {
  buildSanityImageUrl,
  useSanityBaseLanguage,
  useSanityOrganizationPrograms,
  useSanitySettings,
} from '@guider-global/sanity-hooks';
import { RegistrationLimitReachedModal } from 'modals';

export const TraineeRegistrationChooseGuidePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [relationshipId, setRelationshipId] = useState('');
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chosenGuide, setChosenGuide] = useState<{
    userPicture?: string;
    profile?: Partial<IProfile>;
  }>();
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);

  const { reqMemberships } = useMemberships({});
  const { getProfiles } = useProfiles({ waitForAuthentication: true });
  const { reqGoals } = useGoals({ waitForAuthentication: true });
  const [profile] = getProfiles();
  const profileId = profile?.id ?? '';

  const { settings } = useSanitySettings({});

  const theme = useTheme();
  const isMobile = useMobileMediaQuery();

  // base lanuage
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  const navigate = useNavigate();
  const { trackScreenTime } = useMixpanelEvents();
  const organizationSlug = getSubDomain();

  const { programSlug = '', role } = useParams<{
    programSlug: string;
    role: 'guide' | 'trainee';
  }>();

  const membershipTraineeForm = useAppSelector((state) =>
    selectMembershipTraineeForm(state, programSlug),
  );
  const membershipTraineeGoals = membershipTraineeForm?.goals || [];

  useTrackEvent({
    track: {
      eventKey: 'program-trainee-viewed-guide-list',
      eventParams: {
        organization: { organizationSlug },
        program: { organizationSlug, programSlug, includeChooseGuideUrl: true },
      },
    },
  });

  const { isLoadingMatches, matches: getMatches } = useMatches({
    getSilentlyUrl: `/matches?organizationSlug=${organizationSlug}&programSlug=${programSlug}`,
    getSilently: true,
  });
  const matches = getMatches();

  const {
    relationships: getRelationships,
    isLoadingRelationships,
    reqRelationships,
  } = useRelationships({});
  const relationships = getRelationships();

  const { getProgram } = useSanityOrganizationPrograms({});
  const program = getProgram(programSlug);

  const handleRegistrationLimitReachedModalAction = useCallback(() => {
    navigate('/relationships');
  }, [navigate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 6000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!isLoading) return;
    const timer = setTimeout(() => {
      loadingMessageIndex !== 3
        ? setLoadingMessageIndex(loadingMessageIndex + 1)
        : setLoadingMessageIndex(0);
    }, 3000);
    return () => clearTimeout(timer);
  }, [loadingMessageIndex, isLoading]);

  const programType = program?.program_details?.program_type;

  useEffect(() => {
    if (matches.length === 0) {
      trackScreenTime('Trainee Program Registration - No guides found', {
        'Program type': programType?.program_type_text.common?.verb,
        'Program ID': programSlug,
      });
    } else {
      trackScreenTime('Trainee Program Registration - Select a guide', {
        'Program type': programType?.program_type_text.common?.verb,
        'Program ID': programSlug,
        'Count of available guides': matches.length,
      });
    }
  }, [
    matches,
    programSlug,
    programType?.program_type_text.common?.verb,
    trackScreenTime,
  ]);

  if (!program) {
    return <></>;
  }

  const programTypeText = programType?.program_type_text;
  const programVariation = programTypeText?.variations?.individual;
  const programRelationshipLimits = program.relationships.relationship_limits;
  const isTraineeRelationshipLimitSet =
    programRelationshipLimits?.enable_trainee_relationship_limit;
  const traineeRelationshipLimit = isTraineeRelationshipLimitSet
    ? programRelationshipLimits?.trainee_relationship_limit
    : undefined;
  const programRegistrationTraineeGuideLimitModal =
    programTypeText?.variations?.individual?.registration?.registration_trainee
      ?.registration_trainee_guide_limit_modal;
  const programRelationships = relationships.filter((relationship) => {
    const relationshipTraineeProfileIds = (
      relationship.traineeProfiles as IProfile[]
    ).map((relationship) => relationship.id);
    const relationshipIncludesTrainee =
      relationshipTraineeProfileIds.includes(profileId);
    return (
      relationship.programSlug === programSlug &&
      !relationship.isConcluded &&
      relationshipIncludesTrainee
    );
  });

  const showRegistrationLimitReachedModal =
    traineeRelationshipLimit !== undefined
      ? programRelationships.length >= traineeRelationshipLimit
      : false;

  const isLimitModalOpen =
    !isLoadingRelationships() &&
    !isLoadingRelationships() &&
    !isModalOpen &&
    showRegistrationLimitReachedModal;

  const isGuide = role === 'guide';

  const registrationLoadingMessages =
    programVariation?.registration?.registration_trainee
      ?.registration_trainee_choose_a_guide
      ?.registration_trainee_choose_a_guide_loading_state_messages;
  const loadingMessages = registrationLoadingMessages
    ? Object.values(registrationLoadingMessages)
    : [];

  const handleLimitReachedAlert = (message: string) => {
    dispatch(
      showAppAlert({
        severity: 'error',
        message: message,
        timeout: 5000,
      }),
    );
  };

  const renderLoadingMessage = loadingMessages.map((loadingMessage, index) => {
    return (
      <Fade
        in={loadingMessageIndex === index}
        key={`loading-message-${index}`}
        timeout={{ enter: 1000, exit: 1000 }}
      >
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ mt: 2, position: 'absolute' }}
        >
          {loadingMessage}
        </Typography>
      </Fade>
    );
  });

  const renderLoading = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          p: 3,
          height: '500px',
        }}
      >
        <CircularProgress color="secondary" size={56} />
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          {renderLoadingMessage}
        </Box>
      </Box>
    );
  };

  const renderNoMatches = () => {
    return (
      <>
        <PageCard
          title={
            programVariation?.registration?.registration_trainee
              ?.registration_trainee_no_matches_found
              ?.registration_trainee_no_matches_found_title ?? ''
          }
          subtitle={
            programVariation?.registration?.registration_trainee
              ?.registration_trainee_no_matches_found
              ?.registration_trainee_no_matches_found_description ?? ''
          }
          color="info"
          icon={PersonSearchSharp}
        />
        <Typography
          variant="body2"
          sx={{
            mt: isMobile ? 3 : 5,
            mb: isMobile ? 3 : 2,
            textAlign: isMobile ? 'center' : 'left',
            px: isMobile ? 2 : 3,
          }}
        >
          {
            programVariation?.registration?.registration_trainee
              ?.registration_trainee_no_matches_found
              ?.registration_trainee_no_matches_actions_label
          }
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            px: isMobile ? 2 : 3,
          }}
        >
          <Button
            data-cy="pages-ProgramRegistrationPage-Trainee-TraineeRegistrationChooseGuidePage_navigation-button"
            onClick={() => navigate('./../')}
            variant="contained"
            color="info"
            size="large"
            fullWidth={isMobile}
            sx={{
              mr: isMobile ? 0 : 3,
              mb: isMobile ? 2 : 0,
              width: '230px',
              fontWeight: '600',
              maxWidth: '100%',
            }}
          >
            {baseLanguage.globals.common.edit_profile_button_label}
          </Button>
          <Button
            data-cy="pages-ProgramRegistrationPage-Trainee-TraineeRegistrationChooseGuidePage_learn-button"
            onClick={() => navigate('/learn')}
            variant="outlined"
            color="info"
            size="large"
            fullWidth={isMobile}
            sx={{
              width: '230px',
              fontWeight: '600',
              maxWidth: '100%',
            }}
          >
            {baseLanguage.globals.common.explore_learning_hub}
          </Button>
        </Box>
      </>
    );
  };

  const connectWithGuide = async (match?: Partial<IMatchMembership>) => {
    const guideProfile = match?.profile as Partial<IProfile>;
    if (!guideProfile?.id) {
      console.error('No `guideProfile` provided.');
      return;
    }

    setChosenGuide({
      userPicture: match?.userPicture,
      profile: guideProfile,
    });

    const relationshipsResponse = await reqRelationships({
      method: 'POST',
      url: '/relationships',
      // TODO fix types
      // @ts-ignore
      data: {
        guideProfiles: [guideProfile.id],
        traineeProfiles: [profileId],
        programSlug,
        organizationSlug,
        programTypeSlug: programType?.metadata?.id?.current,
        programVariationTypeSlug: EProgramVariation.Individual,
        profileId,
        goals: membershipTraineeGoals,
      },
    });

    relationshipsResponse.errors?.forEach((error) => {
      if (error.message?.includes('relationship limit reached')) {
        handleLimitReachedAlert(error.message);
      }
    });

    const relationshipData = relationshipsResponse?.data;
    const relationship = relationshipData && relationshipData[0];

    if (relationship) {
      dispatch(setMembershipTraineeForm({}));
      const relationshipId = relationship?.id || '';
      setRelationshipId(relationshipId);
      dispatch(showConfetti(true));
      setIsModalOpen(true);
    }

    await Promise.all([
      reqRelationships({ url: '/relationships' }),
      reqMemberships({ url: '/memberships' }),
      reqGoals({ url: '/goals' }),
    ]);
  };

  const renderGuide = (match: IMatch, index: number) => {
    const membership: Partial<IMatchMembership> =
      match.membership as Partial<IMatchMembership>;

    const guideProfile: Partial<IProfile> =
      membership.profile as Partial<IProfile>;
    const guideUserPicture = membership.userPicture;

    if (!guideProfile?.id) {
      return;
    }

    const skills: Partial<ISkill>[] = membership.skills as Partial<ISkill>[];
    const skillsSlugs = skills.map((skill) => skill.fieldSlug || '');

    return (
      <GuideCard
        sx={{ mt: index !== 0 ? 3 : 0, mx: isMobile ? 2 : 3 }}
        key={`guide-card-${index}`}
        guide={{
          organizationFields: guideProfile.organizationFields,
          displayName: guideProfile.displayName,
          firstName: guideProfile.firstName,
          lastName: guideProfile.lastName,
          jobTitle: guideProfile.jobTitle,
          townOrCity: guideProfile.townOrCity,
          linkedInUrl: guideProfile.linkedInUrl,
          pictures: {
            profile: guideProfile.picture,
            user: guideUserPicture,
          },
        }}
        data={{
          skills: skillsSlugs,
          membership: [match.membership] as IMembership[],
        }}
        isBestMatch={index === 0}
        action={async () => {
          setSelectedIndex(index);
          await connectWithGuide(membership);
          trackScreenTime(
            'Trainee Program Registration - Relationship created',
          );
          setSelectedIndex(undefined);
        }}
        blurred={index !== 0}
        disabled={
          (isLoadingRelationships() || isLoadingRelationships()) &&
          index !== selectedIndex
        }
        isLoading={
          (isLoadingRelationships() || isLoadingRelationships()) &&
          index === selectedIndex
        }
      />
    );
  };

  const renderFoundGuide = () => {
    const renderGuides = () =>
      matches.map((match, index) => renderGuide(match, index));

    return (
      <>
        <PageCard
          title={
            programVariation?.registration?.registration_trainee
              ?.registration_trainee_choose_a_guide
              ?.registration_trainee_choose_a_guide_title ?? ''
          }
          subtitle={
            programVariation?.registration?.registration_trainee
              ?.registration_trainee_choose_a_guide
              ?.registration_trainee_choose_a_guide_description ?? ''
          }
          color="success"
        />
        <Divider sx={{ my: 4, mx: isMobile ? 2 : 3 }} />
        {renderGuides()}
      </>
    );
  };

  const renderContent = () => {
    if (matches.length === 0) {
      return renderNoMatches();
    }

    return renderFoundGuide();
  };

  const handleViewRelationship = () => {
    dispatch(hideConfetti(true));
    setIsModalOpen(false);
    trackScreenTime('Trainee Program Registration - View relationship');
    navigate(`/relationships/${relationshipId}`);
  };

  // TODO Move navigate's to a useEffect
  if (!program || !role) {
    navigate('./..');
    return <></>;
  }

  if (isGuide) {
    navigate(`/programs/${programSlug}`);
    return <></>;
  }

  if (
    !membershipTraineeForm ||
    Object.keys(membershipTraineeForm).length === 0
  ) {
    navigate('./..');
    return <></>;
  }

  return (
    <Paper
      sx={{
        pt: isMobile ? 0 : 4,
        pb: 4,
        borderTop: `8px solid ${theme.palette.secondary.main}`,
        ...(isMobile && { borderRadius: '0px' }),
      }}
    >
      {isLoading || isLoadingMatches() ? renderLoading() : renderContent()}
      <RegistrationLimitReachedModal
        open={isLimitModalOpen}
        title={
          programRegistrationTraineeGuideLimitModal?.registration_trainee_guide_limit_modal_title ??
          ''
        }
        content={
          programRegistrationTraineeGuideLimitModal?.registration_trainee_guide_limit_modal_description ??
          ''
        }
        action={{
          label:
            programRegistrationTraineeGuideLimitModal?.registration_trainee_guide_limit_modal_button_label ??
            '',
          action: handleRegistrationLimitReachedModalAction,
          variant: 'contained',
          color: 'info',
        }}
      />
      <Modal open={isModalOpen} aria-labelledby="modal-high-five">
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Card
            sx={{
              width: '530px',
              minHeight: '380px',
              maxwidth: '95%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              py: 6,
              px: 3,
              boxSizing: 'border-box',
            }}
          >
            <Box
              sx={{
                top: '-60px',
                left: '-15%',
                position: 'absolute',
                width: '130%',
                minHeight: '300px',
                borderRadius: '100%',
                backgroundColor: `rgba(${hexToRGB(
                  theme.palette.info.main,
                )}, 0.08)`,
              }}
            />
            <Typography
              variant="h4"
              component="p"
              sx={{ mb: 0.5, textAlign: 'center' }}
            >
              {
                programVariation?.registration?.registration_trainee
                  ?.registration_trainee_relationship_created_successfully
                  ?.registration_trainee_relationship_created_successfully_title
              }
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'center' }}>
              {prepareSanityString(
                programVariation?.registration?.registration_trainee
                  ?.registration_trainee_relationship_created_successfully
                  ?.registration_trainee_relationship_created_successfully_description ??
                  '',
                {
                  firstName: chosenGuide?.profile?.firstName || '',
                  lastName: chosenGuide?.profile?.lastName || '',
                },
              )}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                my: 3,
                mb: 4.5,
                zIndex: 2,
              }}
            >
              <Card
                sx={{ backgroundColor: '#FFFFFF', p: 0.25, mr: -1, zIndex: 3 }}
              >
                <AvatarUser sx={{ width: 112, height: 112 }} />
              </Card>
              <Box
                sx={{
                  borderRadius: '50%',
                  backgroundColor: '#E8F4EC',
                  border: '1px solid #FFFFFF',
                  width: '101px',
                  height: '101px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 2,
                }}
              >
                <Box
                  component="img"
                  src={buildSanityImageUrl({
                    source:
                      settings?.static_media.relationships
                        .relationship_created ?? '',
                    width: 100,
                  })}
                ></Box>
              </Box>
              <Card
                sx={{ backgroundColor: '#FFFFFF', p: 0.25, ml: -1, zIndex: 1 }}
              >
                <GuideAvatar
                  profilePicture={chosenGuide?.profile?.picture}
                  userPicture={chosenGuide?.userPicture}
                  width={112}
                  height={112}
                />
              </Card>
            </Box>
            <Button
              data-cy="pages-ProgramRegistrationPage-Trainee-TraineeRegistrationChooseGuidePage_view-relationship-button"
              variant="contained"
              color="info"
              size="large"
              onClick={handleViewRelationship}
            >
              {baseLanguage.globals.relationships.view_relationship}
            </Button>
          </Card>
        </Box>
      </Modal>
    </Paper>
  );
};
