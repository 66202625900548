import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { LoadingOverlay } from 'components';

import { ProfilePictureForm } from 'forms';
import {
  SessionStorageKeys,
  useMixpanelEvents,
  useSessionStorageTyped,
  useUserPictures,
  useTrackEvent,
} from 'hooks';

import { useAppDispatch } from 'store/hooks';
import { appSlice } from 'store/slices/appSlice';

import { useAuth } from '@guider-global/auth-hooks';
import {
  getISOStringWithoutMilliseconds,
  getSubDomain,
} from '@guider-global/front-end-utils';
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';

interface IOnboardingPictureUploadPageProps {
  getSilentlySanityBaseLanguage?: boolean;
}

export const OnboardingPictureUploadPage: React.FC<
  IOnboardingPictureUploadPageProps
> = ({ getSilentlySanityBaseLanguage = false }) => {
  const { trackScreenTime, setUserProperties } = useMixpanelEvents();
  // Auth (also handles redirect)
  const { isAuthorized } = useAuth({});

  // Router
  const navigate = useNavigate();

  // Redux
  const dispatch = useAppDispatch();
  const { showNavbar } = appSlice.actions;

  // Internal Hooks
  const [redirect] = useSessionStorageTyped(
    SessionStorageKeys.REGISTER_REDIRECT,
  );
  const { picture, loading } = useUserPictures();

  // Base language
  const {
    isLoadingSanityBaseLanguage,
    isSuccessSanityBaseLanguage,
    getBaseLanguage,
  } = useSanityBaseLanguage({
    getSilently: getSilentlySanityBaseLanguage,
  });
  const title: string = getBaseLanguage().onboarding.picture.title;
  const submitButtonLabel: string =
    getBaseLanguage().globals.common.continue_button_label ?? 'Continue';

  // Ready state
  const isReady =
    isAuthorized &&
    !isLoadingSanityBaseLanguage() &&
    isSuccessSanityBaseLanguage();

  const organizationSlug = getSubDomain();

  // Event Controller: Registration - Complete
  const { trackEvent } = useTrackEvent({});

  // Handler: Submit
  const handleSubmit = useCallback(async () => {
    trackScreenTime('Registration - Add photo screen - Continue');
    dispatch(showNavbar(true));

    // Event: Registration - Complete
    await trackEvent({
      eventKey: 'registration-complete',
      eventParams: {
        organization: { organizationSlug },
        programList: { organizationSlug },
      },
    });

    trackScreenTime('Registration - Complete', { 'Is onboarded': true });
    setUserProperties({
      'Is onboarded': true,
      'Completed on': getISOStringWithoutMilliseconds(),
    });

    // Redirect
    if (redirect) {
      sessionStorage.removeItem(SessionStorageKeys.REGISTER_REDIRECT);
      window.history.pushState({}, '', `${origin}/dashboard`);
      try {
        const fullUrl = new URL(redirect as string);
        if (fullUrl) {
          window.location.href = fullUrl.href;
        }
      } catch (_error) {
        navigate(redirect);
        return;
      }
    }
    navigate('/dashboard');
  }, [
    dispatch,
    navigate,
    organizationSlug,
    redirect,
    showNavbar,
    trackEvent,
    trackScreenTime,
    setUserProperties,
  ]);

  return (
    <>
      <LoadingOverlay loading={!isReady} />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          boxSizing: 'border-box',
          p: 3,
        }}
      >
        <Typography component="h1" variant="h4" sx={{ mb: 1.5 }}>
          {title}
        </Typography>
        <ProfilePictureForm defaultFile={picture} loading={loading} />
        <LoadingButton
          fullWidth
          color="info"
          disableElevation
          variant="contained"
          disabled={!isReady || loading}
          loading={!isReady || loading}
          size="large"
          sx={{ mt: 2 }}
          onClick={handleSubmit}
        >
          {submitButtonLabel}
        </LoadingButton>
      </Box>
    </>
  );
};

export default OnboardingPictureUploadPage;
