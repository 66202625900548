import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';

import { OrganizationProfileForm } from 'forms';
import { useProfileForm } from 'forms/ProfileForms/ProfileForm/hooks';
import { showNavbar } from 'store/slices/appSlice';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';

interface IOnboardingProfilePageProps {
  getSanityBaseLanguageSilently?: boolean;
  getSanityOrganizationSilently?: boolean;
  getProfilesSilently?: boolean;
  getUsersSilently?: boolean;
  getOrganizationsSilently?: boolean;
  getCustomFieldsSilently?: boolean;
}

export const OnboardingProfilePage: React.FC<IOnboardingProfilePageProps> = ({
  getSanityBaseLanguageSilently = false,
  getSanityOrganizationSilently = false,
  getProfilesSilently = false,
  getUsersSilently = false,
  getOrganizationsSilently = false,
  getCustomFieldsSilently = false,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { sanityOrganizationOnboardingFields, profile, isReady } =
    useProfileForm({
      getSanityBaseLanguageSilently,
      getSanityOrganizationSilently,
      getProfilesSilently,
      getUsersSilently,
      getOrganizationsSilently,
      getCustomFieldsSilently,
    });

  useEffect(() => {
    if (isReady && profile?.isOnboarded) {
      dispatch(showNavbar(true));
      navigate('/dashboard');
    }
    // the rule is needed until I refactor the profile form so it
    // properly redirects on the onboarding - commit https://github.com/guider-global/platform/commit/99fb6919cfe5ed8bf9363ad7e569a17c2558e3dc
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate, isReady]);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        boxSizing: 'border-box',
        p: 3,
      }}
    >
      <Typography component="h1" variant="h4">
        {sanityOrganizationOnboardingFields.profile.title}
      </Typography>
      <OrganizationProfileForm />
    </Box>
  );
};

export default OnboardingProfilePage;
