// external
import { FC, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar } from '@mui/material';

import { useAuth } from '@guider-global/auth-hooks';
import {
  buildSanityImageUrl,
  useSanityBaseLanguage,
  useSanityOrganization,
} from '@guider-global/sanity-hooks';
import {
  getISOStringWithoutMilliseconds,
  getOrigin,
  getSubDomain,
} from '@guider-global/front-end-utils';
import { OrganizationContainer } from '@guider-global/ui';
import { GenericError, LanguageMenu } from 'components';
import { useLocalization, useMixpanelEvents, useUsers } from 'hooks';

// store
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { appSlice, selectRegistrationType } from 'store/slices/appSlice';
import { getRegistrationMethod } from 'utils';

// utils

export const RegisterPage: FC = () => {
  const organizationSlug = getSubDomain();

  // Auth
  const origin = getOrigin();
  const { isAuthenticated, isLoading } = useAuth({
    redirectUriLogout: `${origin}/register/account`,
  });

  // Router
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;

  // Redux
  const registrationType = useAppSelector(selectRegistrationType);
  const dispatch = useAppDispatch();
  const { hideNavbar } = appSlice.actions;

  // Hooks
  const { users: getUsers } = useUsers({ getSilently: false });
  const [user] = getUsers();

  const { localeCode, handleLanguageChange } =
    useLocalization(organizationSlug);

  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguages = getBaseLanguage();
  const navigation = baseLanguages?.navigation;

  const {
    isErrorSanityOrganization,
    isSuccessSanityOrganization,
    getErrorsSanityOrganization,
    getOrganization,
  } = useSanityOrganization({ organizationSlug, getSilently: false });

  const isOrganization = isSuccessSanityOrganization();
  const organization = getOrganization();
  const organizationWhiteLabel = isOrganization
    ? organization.white_label
    : undefined;
  const organizationLogo = organizationWhiteLabel
    ? organizationWhiteLabel.logo
    : undefined;
  const registrationLogo = organizationWhiteLabel
    ? organizationWhiteLabel.registration_logo
    : undefined;
  const organizationLanguages = isOrganization
    ? organization?.organizationLanguages
    : [];

  const { trackMixpanelEvent, setUserProperties, setOrganizationGroup } =
    useMixpanelEvents({
      navigationEventName: 'Register',
    });

  const logo = registrationLogo ?? organizationLogo;
  const imageSource = logo
    ? buildSanityImageUrl({
        source: logo,
        format: 'png',
        width: 320,
      })
    : undefined;

  const isError = isErrorSanityOrganization();

  // Effects
  useEffect(() => {
    dispatch(hideNavbar(true));
    const isOnRegisterAccount = pathname.includes('/register/account');
    if (!isAuthenticated && !isLoading && !isOnRegisterAccount) {
      navigate('/register/account');
    }
  }, [dispatch, hideNavbar, isAuthenticated, isLoading, navigate, pathname]);

  useEffect(() => {
    trackMixpanelEvent('Registration - Viewed', {
      'Organization ID': organizationSlug,
    });
  }, [organizationSlug, trackMixpanelEvent]);

  useEffect(() => {
    setOrganizationGroup(organizationSlug);
  }, [organizationSlug, setOrganizationGroup]);

  useEffect(() => {
    if (user?.id) {
      setUserProperties({
        user_id: user.id,
        'Registration date': getISOStringWithoutMilliseconds(),
        'Registration method': getRegistrationMethod(registrationType),
        'Organization ID': organizationSlug,
      });
    }
  }, [user?.id, registrationType, organizationSlug, setUserProperties]);

  if (isError) {
    const errors = getErrorsSanityOrganization()
      .map((err) => `${err.code} | ${err.field} | ${err.message}`)
      .join(',');
    return (
      <GenericError
        code={'ORGANIZATION'}
        message={errors}
        visible={true}
        overlay={false}
      />
    );
  }

  return (
    <>
      <AppBar position="sticky" sx={{ boxShadow: 'none' }} color="transparent">
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <LanguageMenu
            localeCode={localeCode}
            languageOptions={organizationLanguages}
            ariaLabel={navigation.user_action_menu.translation_dropdown_label}
            onClickChange={handleLanguageChange}
            arrowColor="primary"
          />
        </Toolbar>
      </AppBar>
      <OrganizationContainer loading={isLoading} imageSource={imageSource}>
        <Outlet />
      </OrganizationContainer>
    </>
  );
};
